<template>
  <div
    class="Link"
    :style="{
      '--link-color': `var(--primary-${theme})`,
      '--link-hover-color': `var(--primary-${theme}_10)`
    }"
  >
    <div class="Link__container" v-for="link in content" :key="link.url">
      <!-- <div class="Link__label">{{ link.label }}</div> -->
      <a class="Link__url" :href="link.url" target="_blank">{{
        link.label || $t('general.cta.discover')
      }}</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    content: Array,
    theme: String,
    label: String
  }
};
</script>
<style lang="scss" scoped>
.Link {
  color: var(--link-color);

  &__container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  &__url {
    @include details;

    letter-spacing: 0.07em;
    text-transform: uppercase;
    padding: 0.5rem 2rem;
    border-radius: 50px;
    margin: 0 7px;
    border: 1px solid var(--link-color);

    &:hover {
      background-color: var(--link-hover-color);
    }
  }
  // &__label {
  //   margin-right: 1rem;
  //   min-width: 7rem;
  // }
}
</style>
