import { Vector3, Group, Raycaster } from "three";

export class SceneContent {
  id;
  container;
  scene;
  camera;
  controls;
  sphereGroup;
  raycaster;
  // vec;
  sceneDimensions;
  textBlocks;
  focusSphereCenter;
  intersectObjects;

  constructor(
    id = null,
    container = null,
    scene = null,
    camera = null,
    controls = null,
    sphereGroup = new Group(),
    raycaster = new Raycaster(),
    // vec = { min: new Vector3(), max: new Vector3() },
    sceneDimensions = { min: new Vector3(), max: new Vector3() },
    // Even though these are also in the sphereGroup, we add them here so that
    // on rotation we don't tax the CPU every time with extracting them from the sphereGroup
    textBlocks = [],
    focusSphereCenter = null
  ) {
    (this.id = id), (this.container = container);
    this.scene = scene;
    this.camera = camera;
    this.controls = controls;
    this.sphereGroup = sphereGroup;
    this.raycaster = raycaster;
    // this.vec = vec;
    this.sceneDimensions = sceneDimensions;
    this.textBlocks = textBlocks;
    this.focusSphereCenter = focusSphereCenter;
    this.intersectObjects = [];
  }
}
