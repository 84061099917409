import {
  TorusGeometry,
  SphereGeometry,
  MeshBasicMaterial,
  ShaderMaterial,
  FrontSide,
  BackSide,
  Mesh,
  UnsignedByteType
} from "three";

import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { vertexShader } from "@/utilities/three-functions/shaders.js";

// TODO: remove all the old stuff and comments when project done
export const threeShapes = {
  data() {
    return {
      ringRadius: 3,
      sphereGeometry: new SphereGeometry(1, 32, 16),
      torusGeometry: null
    };
  },
  created() {
    this.torusGeometry = new TorusGeometry(this.ringRadius, 0.01, 16, 100);
  },
  computed: {
    spheresConfig() {
      if (this.content.scenes) {
        // In terms of size, spheres with descriptions are bigger
        // For rotation the circle is divided in n segments, where n = the amount of texts
        return Object.keys(this.content.scenes).reduce((prevObj, nextKey) => {
          const dataAmount = this.content.scenes[nextKey].projects.length;
          const circleSegment = (Math.PI * 2) / dataAmount;
          // Offset to show circles always more or less center front
          // const spheresOffset = Math.PI * 0.3 * dataAmount * 0.5;

          prevObj[nextKey] = this.content.scenes[nextKey].projects.map(
            (data, i) => {
              return {
                ...data,
                isMain: data.text ? true : false,
                size: data.text ? 0.3 : 0.1,
                radians: circleSegment * i - Math.PI * 0.1,
                glowScale: data.description ? 1.25 : 1.3
              };
            }
          );

          return prevObj;
        }, {});
      }

      return {};
    },

    sphereGroupPosition() {
      return {
        x: 0,
        y: -0.1,
        z: this.ringRadius * -0.5
      };
    }
  },
  methods: {
    addSpheres(
      spheres,
      sceneContent,
      fragmentShader,
      shaderOptions,
      callback,
      groupRotation,
      namePrefix = ""
    ) {
      const middleSphereIndex = Math.floor(spheres.length / 2);
      const oddSpheres = spheres.length % 2 === 1;

      const sphereMaterial = new ShaderMaterial({
        uniforms: {
          ...shaderOptions,
          delta: {
            value: 0.0 //startRotation
          }
        },
        vertexShader,
        fragmentShader,
        transparent: true,
        depthWrite: false,
        side: !!namePrefix ? FrontSide : BackSide
      });

      spheres.forEach((sphere, i) => {
        let rotationFactor = i >= middleSphereIndex ? -1 : 1;
        if (oddSpheres && i === middleSphereIndex) rotationFactor = 0;

        const startRotation =
          (sphere.radians + Math.PI * 0.1) * 0.06 * rotationFactor;

        const sphereMesh = new Mesh(this.sphereGeometry, sphereMaterial);

        sphereMesh.position.x = this.ringRadius * Math.sin(sphere.radians);
        sphereMesh.position.y = this.ringRadius * Math.cos(sphere.radians);

        if (!!namePrefix) {
          sphereMesh.scale.setScalar(sphere.size);
          sphereMesh.callback = () => callback(sphereMesh);
          sphereMesh.name = "sphere_" + namePrefix + i;
          spheres[i].mesh = sphereMesh;

          this.addTextToSphere(
            sphere,
            sphereMesh,
            sphere.isMain,
            groupRotation,
            sceneContent,
            namePrefix + i
          );
        } else {
          sphereMesh.scale.setScalar(sphere.size * 2.25);
          sphereMesh.startRotation = startRotation;
        }

        sceneContent.intersectObjects.push(sphereMesh);
        sceneContent.sphereGroup.add(sphereMesh);
      });
    },

    createRing() {
      const ringMaterial = new MeshBasicMaterial({
        color: this.ringColor,
        toneMapped: false
      });
      return new Mesh(this.torusGeometry, ringMaterial);
    },

    addRing(group, ringGroupRotation, ringGroupPosition) {
      const ringMesh = this.createRing();

      group.add(ringMesh);
      // With rotation, take into account that once one axis is rotated, other axes end up somewhere else
      group.rotation.x = ringGroupRotation.x;
      if (ringGroupRotation.z) group.rotation.z = ringGroupRotation.z;
      group.position.y = ringGroupPosition.y;
    },

    // Generic function to add GLTF models
    // For position and rotation, optionally define x, y, z. The input would be e.g.
    // {x: 5} to only change x axis
    // {x: 5, z: 5} to only change x and z axis
    addModel(sceneContent, scenesConfig) {
      this.addEnvironment(sceneContent.scene);

      this.gltfLoader.load(
        `${this.$baseUrl}models/${this.pageId}/${scenesConfig.folder}/scene.gltf`,
        model => {
          let resultModel;

          resultModel = model.scene;

          if (scenesConfig.modelCallback) {
            scenesConfig.modelCallback(resultModel);
          }

          resultModel.traverse(child => {
            if (child.isMesh) {
              child.displacementScale = 1;
              child.displacementBias = 1;
              child.material.envMap = sceneContent.scene.environment;
              this.roughnessMipmapper.generateMipmaps(child.material);
              // child.name = "model";
              // sceneContent.intersectObjects.push(child);
            }
          });

          this.configureModel(resultModel, scenesConfig);

          // This is to make sure we cannot click through model
          // but has too high of an impact on performance with models with a lot of meshes
          // sceneContent.intersectObjects.push(resultModel);
          sceneContent.scene.add(resultModel);
        }
      );
    },

    configureModel(model, scenesConfig) {
      model.scale.setScalar(scenesConfig.scale);

      ["x", "y", "z"].forEach(key => {
        if (scenesConfig.rotation)
          model.rotation[key] =
            scenesConfig.rotation[key] || model.rotation[key];

        if (scenesConfig.position)
          model.position[key] =
            scenesConfig.position[key] || model.position[key];
      });
    },

    // Add an environment for GLTF model
    addEnvironment(scene) {
      new RGBELoader()
        .setDataType(UnsignedByteType)
        .load(
          `${this.$baseUrl}models/${this.pageId}/${this.pageId}.hdr`,
          texture => {
            const envMap = this.pmremGenerator.fromEquirectangular(texture)
              .texture;
            scene.environment = envMap;

            // scene.background = envMap;

            texture.dispose();
            this.pmremGenerator.dispose();
          }
        );
    },

    // This takes care of rendering each scene on the canvas
    renderSceneOnScreen(sceneContent) {
      const {
        left,
        right,
        bottom,
        width,
        height
      } = sceneContent.container.getBoundingClientRect();

      // Don't render yet if scene is offscreen
      // We use an offset of 1 to make sure the adjacent scenes immediately render
      // when transition to the next scene starts
      const isOffscreen =
        right < 1 || left > this.renderer.domElement.clientWidth - 1;

      if (isOffscreen) {
        return;
      }

      // for some weird reason the canvas has width & height double from the viewport
      const positiveYUpBottom = this.renderer.domElement.height * 0.5 - bottom;

      this.renderer.setScissor(left, positiveYUpBottom, width, height);
      this.renderer.setViewport(left, positiveYUpBottom, width, height);

      this.renderer.render(sceneContent.scene, sceneContent.camera);
    }
  }
};
