export const vertexShader = `
        varying vec3 vNormal;
        varying vec3 vNormel;

        uniform float delta;

        void main()
        {
          vNormal = normalize( normalMatrix * normal );
          vNormel = vec3(0,0,1);
          
          vec3 p = position.xyz;
          float new_x = p.x*cos(delta) - p.y*sin(delta);
          float new_y = p.y*cos(delta) + p.x*sin(delta);
      
          gl_Position = projectionMatrix * modelViewMatrix * vec4(new_x, new_y, p.z, 1.0);

          //gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
      `;

export const radialGradientShader = `
      uniform vec3 color1;
      uniform vec3 color2;
      uniform vec3 color3;

      uniform float step1;
      uniform float step2;
      uniform float step3;
    
      varying vec3 vNormal;
      
      void main() {
        vec3 radial = mix(color1, color2, smoothstep(step1, step2, vNormal.z));
        radial = mix(radial, color3, smoothstep(step2, step3, vNormal.z));
        
        gl_FragColor = vec4(radial, 0.95);
      }
    `;

export const radialGlowShader = `
      uniform vec3 color1;
      uniform vec3 color2;

      uniform float step1;
      uniform float step2;

      uniform float opacity;

      varying vec3 vNormal;
      
      void main() {
        // We do * -1.0 to show both colors on backside
        vec3 radial = mix(color1, color2, smoothstep(step1, step2, vNormal.z * -1.0));

        gl_FragColor = vec4(radial, vNormal.z * vNormal.z * vNormal.z * -0.75 );
      }
    `;
