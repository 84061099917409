import { Vector2, Vector3 } from "three";

export const threeInteraction = {
  data() {
    return {
      prevCameraPosition: null,
      mouse: new Vector2(),
      clickedSphere: { sceneId: null, sphereIndex: null },
      currentSceneIndex: 0,
      sceneChanged: false,
      hasPointer: false,
      hasIntersecteds: [], // Array to check what the latest intersected objects are, to better manage hovers
      prevTouches: { x: 0, y: 0 },
      exitOffset: 2.5,
      interact: true
    };
  },
  methods: {
    setInteract(value) {
      this.interact = value;
    },
    onSwipeStart(e) {
      if (e.touches && e.touches.length > 0) {
        const touch = e.touches[0];
        this.prevTouches = { x: touch.clientX, y: touch.clientY };
      }
    },

    onSwipe(e) {
      if (this.interact && e.touches && e.touches.length === 2) {
        const touch = e.touches[0];

        // Subtract x from y to always navigate in the direction of largest non-absolute number
        this.changeSceneFromScroll(
          touch.clientX + touch.clientY <
            this.prevTouches.x + this.prevTouches.y,
          touch.clientX + touch.clientY >
            this.prevTouches.x + this.prevTouches.y
        );
      }
    },

    onMouseWheel(e) {
      if (this.interact || !this.smallscreen) {
        // Subtract x from y to always navigate in the direction of largest non-absolute number
        this.changeSceneFromScroll(
          e.deltaX + e.deltaY > 1,
          e.deltaX + e.deltaY < -1
        );
      }
    },

    changeSceneFromMenu(newSceneId) {
      if (this.clickedSphere.sceneId) {
        this.closeDetails().onComplete(() => {
          const prevCamPosition = this.scenes[this.currentSceneIndex].camera
            .position;

          this.changeScene(newSceneId, prevCamPosition);
        });
      } else {
        const prevCamPosition = this.scenes[this.currentSceneIndex].camera
          .position;

        this.changeScene(newSceneId, prevCamPosition);
      }
    },

    changeSceneFromScroll(toNext, toPrev) {
      // Scroll correct scene into view, one scene per scroll
      if (!this.sceneChanged && !this.clickedSphere.sceneId) {
        const prevCamPosition = this.scenes[this.currentSceneIndex].camera
          .position;

        if (
          this.currentSceneIndex >= 0 &&
          this.currentSceneIndex <= this.scenes.length - 1
        ) {
          if (toNext && this.currentSceneIndex < this.scenes.length - 1) {
            this.changeScene(this.currentSceneIndex + 1, prevCamPosition);
          } else if (toPrev && this.currentSceneIndex > 0) {
            this.changeScene(this.currentSceneIndex - 1, prevCamPosition);
          }
        }
      }
    },

    changeScene(newSceneId, prevCamPosition) {
      this.currentSceneIndex = newSceneId;
      this.flyOut(prevCamPosition);
      this.flyIn(this.scenes[newSceneId].camera.position);
      this.sceneChanged = true;
    },

    flyOut(prevCamPosition) {
      this.setTween(prevCamPosition, {
        x: prevCamPosition.x - this.exitOffset
      }).onComplete(() => (prevCamPosition.x += this.exitOffset));
    },

    flyIn(nextCamPosition) {
      nextCamPosition.x -= this.exitOffset;
      this.setTween(nextCamPosition, {
        x: nextCamPosition.x + this.exitOffset
      }).onComplete(() => (this.sceneChanged = false));
    },

    onSceneInteraction(e, callback) {
      this.scenes.forEach(scene => {
        this.onMouseInteraction(e, scene, callback);
      });
    },
    onSceneClick(intersects) {
      if (
        this.clickedSphere.sphereIndex === null &&
        intersects.length > 0 &&
        // This callback can be found in three-shapes in addSpheres
        intersects[0].object.callback
      ) {
        intersects[0].object.callback(intersects[0].object);
        document.querySelector("body").style.cursor = "initial";
      }
    },
    onSceneHover(intersects) {
      // Sphere hover seems to take into account all scenes, which is why we check with the hasIntersecteds array
      // if every n (= amount of scenes) intersects arrays are empty
      if (intersects.length > 0 && !!intersects[0].object.name) {
        if (!this.hasIntersecteds.some(item => item)) {
          // All spheres have scene in the name
          if (intersects[0].object.name.includes("scene")) {
            document.querySelector("body").style.cursor = "pointer";
          }
        }

        this.hasIntersecteds.push(true);
      } else if (intersects.length === 0) {
        this.hasIntersecteds.push(false);

        if (
          this.hasIntersecteds.length === this.scenes.length &&
          this.hasIntersecteds.every(item => !item)
        ) {
          document.querySelector("body").style.cursor = "initial";
        }
      }

      if (this.hasIntersecteds.length === this.scenes.length) {
        this.hasIntersecteds = [];
      }
    },

    // Navigating to project by rotating spheregroup
    navigateToProject(direction) {
      const scene = this.scenes[this.currentSceneIndex];
      const amountOfProjects = this.content.scenes[this.clickedSphere.sceneId]
        .projects.length;

      let newSphereIndex = this.clickedSphere.sphereIndex + direction;

      if (newSphereIndex === -1) newSphereIndex = amountOfProjects - 1;
      if (newSphereIndex === amountOfProjects) newSphereIndex = 0;

      if (scene.focusSphereCenter === null) {
        scene.focusSphereCenter = this.clickedSphere.sphereIndex;
      }

      const focusSphereRadians = this.spheresConfig[scene.id][
        scene.focusSphereCenter
      ].radians;

      const rotationValue = this.spheresConfig[scene.id][newSphereIndex]
        .radians;

      this.setTween(scene.sphereGroup.rotation, {
        z: (focusSphereRadians - rotationValue) * -1
      });

      this.clickedSphere.sphereIndex = newSphereIndex;
    },

    onSphereClick(sphereMesh) {
      if (!this.clickedSphere.sceneId && (this.interact || !this.smallscreen)) {
        const [_, sceneId, sceneIndex, sphereIndex] = sphereMesh.name.split(
          "_"
        );
        const scene = this.scenes[sceneIndex];
        const mesh = this.spheresConfig[sceneId][sphereIndex].mesh;

        this.prevCameraPosition = {
          x: scene.camera.position.x,
          y: scene.camera.position.y,
          z: scene.camera.position.z
        };

        this.centerSphere(mesh, sphereIndex, scene, sceneId);
      }
    },

    calculateMeshCenter(mesh) {
      mesh.geometry.computeBoundingBox();
      const center = new Vector3();
      mesh.geometry.boundingBox.getCenter(center);
      mesh.localToWorld(center);
      return center;
    },

    centerSphere(sphereMesh, sphereIndex, scene, sceneId) {
      const center = this.calculateMeshCenter(sphereMesh);

      // 2)
      // const focusSphereIndex = this.scenesConfig[this.currentSceneIndex]
      //   .sphereFocusIndex;
      // if (!scene.focusSphereCenter) {
      //   scene.focusSphereCenter = this.calculateMeshCenter(
      //     this.spheresConfig[sceneId][focusSphereIndex].mesh
      //   );
      // }

      scene.controls.enableRotate = false;
      scene.controls.autoRotate = false;
      this.clickedSphere.sphereIndex = +sphereIndex;
      this.clickedSphere.sceneId = sceneId;

      // this.prevCameraRotation = { y: scene.camera.rotation.y };
      // const rotationValue = this.spheresConfig[sceneId][sphereIndex].radians;
      // const focusSphereRadians = this.spheresConfig[sceneId][focusSphereIndex]
      //   .radians;

      // this.setTween(scene.sphereGroup.rotation, {
      //   z: (focusSphereRadians - rotationValue) * -1 // + cameraRotationOffset
      // });

      this.setTween(scene.controls.target, {
        x: center.x,
        y: center.y,
        z: center.z
      }).onUpdate(() => {
        scene.controls.update();
      });

      this.updateTextOpacity(scene.textBlocks, 0);
    },

    closeDetails() {
      if (this.clickedSphere.sceneId) {
        const scene = this.scenes.find(
          scene => scene.id === this.clickedSphere.sceneId
        );
        scene.controls.enableRotate = true;
        scene.controls.autoRotate = true;
        scene.textBlocks.forEach(text => (text.visible = true));
        scene.focusSphereCenter = null;

        const closeTween = this.setTween(scene.camera.position, {
          ...this.prevCameraPosition
        });

        this.setTween(scene.sphereGroup.rotation, {
          z: this.scenesConfig[this.currentSceneIndex].ringGroupRotation.z || 0
        });

        this.setTween(scene.controls.target, {
          x: 0,
          y: 0,
          z: 0
        }).onUpdate(() => {
          scene.controls.update();
        });

        this.updateTextOpacity(scene.textBlocks, 1);

        this.clickedSphere.sceneId = null;
        this.clickedSphere.sphereIndex = null;

        // Return tween to be able to detect when animation is done when calling this function
        return closeTween;
      }
    },

    updateTextOpacity(textBlocks, opacity) {
      textBlocks.forEach(block => {
        block.traverse(child => {
          if (child.material) {
            if (!opacity || !child.name.includes("plane")) {
              this.setTween(child.material, { opacity });
            } else if (child.name.includes("plane")) {
              this.setTween(child.material, { opacity: 0.5 });
            }
          }
        });
      });
    },

    onMouseInteraction(event, scene, callback) {
      if (this.clickedSphere.sphereIndex === null) {
        event.preventDefault();

        // Correct for the fact that we have 3 translated scenes on 1 canvas container
        const containerX =
          (scene.container.getBoundingClientRect().x /
            scene.container.clientWidth) *
            -2 -
          1;
        const smallscreenOffset = 0;
        // this.smallscreen
        // ? window.innerHeight * 0.25 // 0.25 is linked to $scene-height in molecules.scss
        // : 0;

        // Places mouse on 1 / -1 grid of three js
        this.mouse.x =
          (event.clientX / scene.container.clientWidth) * 2 + containerX;
        this.mouse.y =
          -(
            (event.clientY - smallscreenOffset) /
            scene.container.clientHeight
          ) *
            2 +
          1;

        scene.raycaster.setFromCamera(this.mouse, scene.camera);
        const intersects = scene.raycaster.intersectObjects(
          scene.intersectObjects
        );

        callback(intersects);
      }
    }
  }
};
