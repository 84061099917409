<template>
  <div
    class="Menu3D"
    :class="[theme, { active: menuShown }]"
    :style="{
      '--menu-color': `var(--primary-${theme}_60)`,
      '--label-hover': `var(--primary-${theme}_10)`
    }"
  >
    <MenuCloud v-if="!smallscreen" :theme="theme" />

    <div class="Menu3D__first-level">
      <button
        class="Menu3D__first-level__label"
        :class="[theme]"
        @click="toLanding"
      >
        {{ content.label }}
      </button>
    </div>

    <div class="Menu3D__second-level">
      <button
        class="Menu3D__project-labels__container"
        v-for="(project, index) in content.projects"
        :class="{ active: selectedProjectIndex === index }"
        :key="project.projectId"
        @click="selectProject(index)"
      >
        <div
          class="Menu3D__project-labels"
          :class="{ active: selectedProjectIndex === index }"
        >
          {{ project.label }}
        </div>
        <div
          class="Menu3D__project-labels__active"
          v-if="selectedProjectIndex === index"
        ></div>
      </button>
    </div>

    <div v-if="smallscreen" class="Menu3D__nav-handle">
      <button class="Menu3D__nav-handle__show-btn" @click="toggleMenu">
        <span class="Menu3D__nav-handle__show-btn__text">{{
          $t("general.cta." + (menuShown ? "close" : "navigate"))
        }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import MenuCloud from "./MenuCloud.vue";

import { mapActions } from "vuex";

export default {
  props: {
    content: {
      type: Object
    },
    selectedProjectIndex: {
      type: Number
    },
    theme: {
      type: String
    },
    clickedOutside: Boolean
  },
  data() {
    return {
      menuShown: false,
      themeIndices: {
        sky: 5,
        earth: 6,
        sea: 7
      }
    };
  },
  computed: {
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    }
  },
  methods: {
    selectProject(sceneIndex) {
      this.$emit("selectedScene", sceneIndex);
      this.menuShown = false;
    },
    toggleMenu() {
      this.menuShown = !this.menuShown;
      this.$emit("menuToggled", this.menuShown);
    },
    toLanding() {
      this.setIndexFromMenu(this.themeIndices[this.theme]);
      this.$router.push({ name: "landing" });
    },
    ...mapActions(["setIndexFromMenu"])
  },
  watch: {
    clickedOutside(value) {
      if (value) {
        this.toggleMenu();
      }
    }
  },
  components: {
    MenuCloud
  }
};
</script>

<style lang="scss" scoped>
.Menu3D {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;

  &__first-level {
    width: calc(#{$side-bar-width} * 0.5);
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      $white 32.81%
    );
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &__label {
      @include button-m-bold-serif;
      @include color-combinations-with-border;

      font-weight: 600;
      transform-origin: center;
      transform: rotate(270deg);
      text-transform: uppercase;
      padding: 4px 10px;
      filter: drop-shadow(0px 0px 7px rgba(114, 185, 117, 0.6));
      border-radius: 10px;

      &:hover {
        background-color: var(--label-hover);
      }
    }
  }
  &__second-level {
    width: calc(#{$side-bar-width} * 0.5);
    height: 100%;
    padding: 20px 0;
    box-shadow: $white-shadow;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__project-labels {
    @include button-m-bold;

    text-transform: uppercase;
    white-space: nowrap;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-shadow: $white-shadow;

    &.active {
      color: $white;
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      transform-origin: center;
      position: relative;
      color: $white_70;

      &:hover {
        color: $white;
      }
    }
    &__active {
      height: 100%;
      width: 6px;
      position: absolute;
      right: -3px;
      background: $white;
      box-shadow: $white-shadow;
    }
  }

  .mobile &,
  .tablet & {
    $handle-width: 1rem;

    transform: translateX(calc(-100% - #{$handle-width} + 2px));
    transition: transform 0.5s;

    &.active {
      transform: translateX(0);
    }

    .Menu3D {
      &__first-level,
      &__second-level {
        background: var(--menu-color);
        z-index: 2;
      }

      &__first-level {
        opacity: 1;

        &__label {
          background-color: $white_70;
        }
      }

      &__project-labels {
        font-size: 0.875rem;
      }

      &__nav-handle {
        position: absolute;
        top: 0;
        left: 100%;
        height: 100%;
        width: $handle-width;
        background-color: var(--menu-color);

        &__show-btn {
          $btn-width: 2rem;

          font-size: 1.4rem;
          font-weight: bold;
          color: $white;
          position: absolute;
          top: 1.5rem;
          left: $handle-width;
          width: $btn-width;
          height: 10rem;
          background-color: var(--menu-color);
          text-transform: uppercase;
          border-radius: 0 25px 25px 0;
          padding-left: 0.25rem;
          padding-right: 0.75rem;

          &::before,
          &::after {
            content: "";
            position: absolute;
            left: 0;
            width: calc(#{$btn-width} * 0.5);
            height: calc(#{$btn-width} * 0.5);
            background-color: var(--menu-color);
          }
          &::before {
            top: 0;
            transform: translateY(-100%);
            // Values should be $btn-width * 0.5 and * 0.25 respectively
            clip-path: path("M0 0, V 16, H 16, C 8 16, 0 8, 0 0");
          }

          &::after {
            top: 100%;
            clip-path: path("M0 0, V 16, C 0 8, 8 0, 16 0, H 0");
          }

          &__text {
            font-size: 1rem;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>
