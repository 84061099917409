<template>
  <h2 class="ProjectTitle" :class="[theme]" v-html="content"></h2>
</template>
<script>
export default {
  props: {
    content: String,
    theme: String
  }
};
</script>
<style lang="scss" scoped>
.ProjectTitle {
  @include color-combinations;
  
  margin: 1.88rem 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.8rem;

  .mobile & {
    font-size: 1.375rem;
  }
}
</style>
