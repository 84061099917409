<template>
  <div
    :class="['scene-setup', pageId, { sceneselected: !!clickedSphere.sceneId }]"
    v-touch:start="swipeStarted"
    v-touch:moving="swiped"
  >
    <!-- Title only visible for assistive technology -->
    <h1 class="scene-setup__accessibile-title">
      {{ content.intro.title + ": " + content.menu[currentSceneIndex].label }}
    </h1>
    <PageBackground v-if="!smallscreen" class="scene-setup__background" />

    <canvas :id="pageId" />
    <div
      :class="['scene-setup__scenes', scenes[currentSceneIndex].id]"
      :style="{ transform: `translateX(${sceneTranslateOffset}%)` }"
    >
      <div
        v-for="scene of scenes"
        :key="scene.id"
        :id="scene.id"
        :class="[
          'scene-setup__scenes__scene',
          { selected: clickedSphere.sceneId === scene.id }
        ]"
      />
    </div>

    <SceneTopBar
      :class="[
        'scene-setup__top-bar',
        { show: clickedSphere.sphereIndex === null }
      ]"
      :theme="pageId"
      :currentSceneIndex="currentSceneIndex"
      :audioTrack="pageId"
      :autoPlay="true"
      @onShowLegend="$emit('toggleInteraction', !$event)"
    />

    <div
      :class="['scene-setup__menu-backdrop', { menuactive }]"
      v-if="smallscreen"
      @click.stop="onClickOutside"
    />

    <transition mode="out-in" name="fade">
      <Menu3D
        v-if="!smallscreen || clickedSphere.sphereIndex === null"
        :content="menuContent"
        :selectedProjectIndex="currentSceneIndex"
        :theme="pageId"
        :clickedOutside="clickedOutside"
        @selectedScene="onSceneSelect"
        @menuToggled="onMenuToggled"
      />
    </transition>

    <Loader class="scene-setup__loader" v-if="!loaded" />

    <transition mode="out-in" name="fade">
      <div>
        <IconButton
          v-if="smallscreen && clickedSphere.sphereIndex !== null"
          key="close-btn"
          class="scene-setup__close-btn"
          icon="close"
          iconfill="stroke"
          @onClick="$emit('closeDetails')"
        />

        <ProjectNavigation
          key="project-nav"
          class="scene-setup__project-navigation"
          v-if="clickedSphere.sphereIndex !== null"
          :theme="pageId"
          :navContent="content.projectNavigation"
          @toPreviousProject="$emit('toProject', -1)"
          @toNextProject="$emit('toProject', 1)"
        />
      </div>
    </transition>
    
    <transition mode="out-in" name="fade">
      <DetailPanel
        key="detail-panel"
        v-if="clickedSphere.sphereIndex !== null"
        :components="detailContent"
        :theme="pageId"
        @closeDetails="$emit('closeDetails')"
      />
    </transition>

    <transition mode="out-in" name="fade">
      <div
        class="scene-setup__scene-nav"
        v-if="clickedSphere.sphereIndex === null"
      >
        <IconButton
          icon="arrow-left"
          :height="arrowSize"
          label="Va al progetto scorso"
          @onClick="onSceneSelect(currentSceneIndex - 1)"
          :disabled="currentSceneIndex === 0"
        />
        <IconButton
          class="next"
          icon="arrow-left"
          label="Va al prossimo progetto"
          :height="arrowSize"
          @onClick="onSceneSelect(currentSceneIndex + 1)"
          :disabled="currentSceneIndex === scenes.length - 1"
        />
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * SETUP EXPLANATION
 *
 * This component is used in the Terra3D / Ciel3D / Mare3D components,
 * these components contain config and css specific for the elements
 *
 * The idea is that every element (cielo/terra/mare) has 1 canvas with 3 scenes.
 * This has less potential problems and is potentially more performant than 3 canvasses.
 * https://r105.threejsfundamentals.org/threejs/lessons/threejs-multiple-scenes.html
 * - Every scene has its own camera, controls, container etc. I made a class SceneContent,
 * - each scene has an instance of this class that stores everything related to the scene
 *
 * - I'm using mixins to organise the code a bit more.
 * -- In three-setup there is the code necessary to setup a 3D scene
 * -- In three-shapes there are the basic shapes of the scene
 * -- in three-texts there is functionality to show the texts in the 3D scene and detail panel
 * -- In three-interaction there is functionality for mouse interaction (it only contains
 *    the general Three js code necessary to detect a mouse on an object)
 *
 * - The 3D stuff for each scene is executed in the function init3D, that is called in the mounted hook.
 * -- In init3D, for each scene the addSceneSetup function is called, that has the code to set up each scene
 * -- addSceneSetup has a parameter 'modelCallback', that is used to place a different model
 *    in each scene
 *
 * - The scenes are transitioned using the eventListener 'wheel' for desktop, and
 *   vue touch event 'moving' for mobile. They both make use of the 'changeSceneFromSwipe' function
 *
 * - Whenever I'm animating a 3D object, I'm using the function setTween.
 * - The transitions are a mix of 3D animations and css transitions, because some
 *    things were way more easy to do in CSS
 */

import PageBackground from "@/components/PageBackground.vue";
import Loader from "@/components/UI/Loader.vue";
import Menu3D from "@/components/UI/3DScenes/Menu3D";
import DetailPanel from "@/components/UI/3DScenes/DetailPanel.vue";
import ProjectNavigation from "@/components/UI/3DScenes/ProjectNavigation.vue";
import SceneTopBar from "@/components/UI/3DScenes/SceneTopBar.vue";
import IconButton from "./IconButton.vue";

export default {
  props: {
    pageId: {
      type: String,
      required: true
    },
    content: {
      type: Object,
      required: true
    },
    scenes: {
      type: Array,
      default: () => {}
    },
    clickedSphere: Object,
    currentSceneIndex: Number,
    loaded: Boolean
  },
  data() {
    return {
      menuactive: false,
      clickedOutside: false
    };
  },
  computed: {
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    },
    menuContent() {
      return {
        label: this.content.intro.title,
        projects: this.content.menu
      };
    },
    sceneContent() {
      return this.content.scenes[this.clickedSphere.sceneId];
    },
    detailContent() {
      return this.sceneContent.projects[this.clickedSphere.sphereIndex]
        .components;
    },
    sceneTranslateOffset() {
      return this.currentSceneIndex * (100 / this.scenes.length) * -1;
    },
    arrowSize() {
      return this.smallscreen ? "1.25rem" : "1.75rem";
    }
  },
  methods: {
    swipeStarted(e) {
      this.$emit("onSwipeStart", e);
    },
    swiped(e) {
      this.$emit("onSwipe", e);
    },
    onSceneSelect(sceneIndex) {
      this.menuactive = false;
      this.$emit("setNewScene", sceneIndex);
    },
    onMenuToggled(value) {
      this.clickedOutside = false;
      this.menuactive = value;
      this.$emit("toggleInteraction", !value);
    },
    onClickOutside() {
      this.clickedOutside = true;
    }
  },
  components: {
    PageBackground,
    Loader,
    Menu3D,
    DetailPanel,
    ProjectNavigation,
    SceneTopBar,
    IconButton
  }
};
</script>

<style lang="scss" scoped>
.scene-setup {
  position: relative;

  // Second param in scene-layout should be amount of scenes - 1
  &.sky {
    @include scene-layout("sky", 4);
  }

  &.earth {
    @include scene-layout("earth", 3);
  }

  &.sea {
    @include scene-layout("sea", 2);
  }

  position: relative;
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  &__accessibile-title {
    opacity: 0;
    position: absolute;
  }

  &__scenes {
    display: flex;
    width: fit-content;
    transition: transform 1.5s;

    &__scene {
      transition: transform 1s;
    }

    .selected {
      transform: translateX(-25%);
    }
  }

  &__top-bar {
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s;

    &.show {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__loader {
    @include center;
    position: absolute;
  }

  &__project-navigation {
    position: absolute;
    width: calc(50% - #{$side-bar-width});
    top: 5rem;
    left: $side-bar-width;
  }

  &__close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__scene-nav {
    position: absolute;
    bottom: 1rem;
    right: 6rem;
    display: flex;

    .next {
      margin-left: 1rem;
      transform: rotate(180deg);
    }
  }

  .tablet &,
  .mobile & {
    .scene-setup__menu-backdrop {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(7px);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s;

      &.menuactive {
        opacity: 1;
        pointer-events: initial;
      }
    }

    .scene-setup__project-navigation {
      $nav-margin: 2rem;

      left: $nav-margin;
      width: calc(100% - (#{$nav-margin} * 2));
    }

    .scene-setup__scene-nav {
      right: 4.5rem;
    }
  }
}
</style>
