<template>
  <div class="scene-top-bar">
    <div class="scene-top-bar__left">
      <!-- Img is png because the corners are round, and so the background "behind"
        the corners is black in a jpg -->
      <transition mode="out-in" name="fade">
        <div
          v-if="legendShown && !smallscreen"
          class="scene-top-bar__left__legend"
        >
          <img
            :src="
              `${$baseUrl}images/projects/${theme}/${theme}-legend-${language}${
                $retina ? '@2x' : ''
              }.png`
            "
            alt=""
          />
          <button
            class="scene-top-bar__left__legend__close-btn"
            :aria-label="$t('general.cta.close') + ' legend'"
            @click="showLegend(false)"
          >
            <svgicon name="close" color="r-var(--white)" height="1rem" />
          </button>
        </div>

        <IconButton
          v-if="!legendShown || smallscreen"
          class="scene-top-bar__left__info-btn"
          icon="info"
          aria-label="info"
          :height="smallscreen ? '1.56rem' : '2.19rem'"
          @onClick="showLegend(true)"
        />
      </transition>

      <AudioPlayer
        :class="[
          'scene-top-bar__left__listen-btn',
          { 'player-shown': legendShown }
        ]"
        :audioTrack="audioTrack"
        :autoPlay="false"
      />
    </div>

    <IconButton
      class="scene-top-bar__close-link"
      icon="close"
      iconfill="stroke"
      label="Ritorna al menu"
      :height="smallscreen ? '1.05rem' : '1.44rem'"
      @onClick="toLanding"
    />

    <transition mode="out-in" name="fade">
      <InfoPopup
        v-if="smallscreen && legendShown"
        :theme="theme"
        :legendImage="`${theme}-legend`"
        @close="showLegend(false)"
      />
    </transition>
  </div>
</template>

<script>
import AudioPlayer from "../AudioPlayer.vue";
import IconButton from "../IconButton.vue";
import InfoPopup from "./InfoPopup.vue";

import { mapActions } from "vuex";

export default {
  components: { IconButton, InfoPopup, AudioPlayer },
  props: {
    theme: {
      type: String,
      required: true
    },
    audioTrack: String,
    currentSceneIndex: Number
  },
  data() {
    return {
      onBoardingKey: "terna-cielo-terra-mare-onboarding",
      onBoarded: false,
      legendShown: true,
      themeIndices: {
        sky: 5,
        earth: 6,
        sea: 7
      },
      language: null
    };
  },
  computed: {
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    }
  },
  created() {
    this.language = this.$i18n.locale;
    this.onBoarded = !!localStorage.getItem(this.onBoardingKey);

    if (this.smallscreen && this.onBoarded) {
      this.showLegend(false);
    }
  },
  methods: {
    toLanding() {
      this.setIndexFromMenu(this.themeIndices[this.theme]);
      // Do this for the cases that cursor would stay pointer because it had hovered on top of 3D text earlier
      document.querySelector("body").style.cursor = "initial";
      this.$router.push({ name: "landing" });
    },
    showLegend(value) {
      this.legendShown = value;

      if (!this.legendShown && !this.onBoarded) {
        localStorage.setItem(this.onBoardingKey, true);
        this.onBoarded = true;
      }

      this.$emit("onShowLegend", this.legendShown);
    },
    ...mapActions(["setIndexFromMenu"])
  },
  watch: {
    currentSceneIndex() {
      // Whenever currentSceneIndex changes, remove legend
      this.showLegend(false);
      this.onBoarded = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.scene-top-bar {
  $bar-left: calc(#{$side-bar-width} + 1.5rem);
  $legend-width: 487px;

  position: absolute;
  top: 1.5rem;
  left: $bar-left;
  width: calc(100% - #{$bar-left});
  display: flex;
  justify-content: space-between;

  &__left {
    position: relative;
    display: flex;
    align-items: flex-start;

    &__listen-btn {
      position: absolute;
      left: 1rem;
      transform: translateX($icon-btn-size);
      transition: transform 1s;

      &.player-shown {
        transform: translateX($legend-width);
      }
    }

    &__legend,
    &__info-btn {
      transition-duration: 0.5s;
    }

    &__legend {
      position: relative;

      img {
        width: $legend-width;
      }

      &__close-btn {
        position: absolute;
        top: 0.75rem;
        right: 0.5rem;
      }
    }
  }

  &__close-link {
    margin-right: 3rem;
  }

  .tablet &,
  .mobile & {
    top: 1rem;
    justify-content: flex-end;
    &__left {
      &__listen-btn {
        position: relative;
        transform: none;
        left: auto;
        margin-left: .6rem;
      }
    }

    &__close-link {
      margin-right: 1rem;
      margin-left: 0.5rem;
    }
  }
}
</style>
