<template>
  <p class="Paragraph" :class="[theme]" v-html="content"></p>
</template>
<script>
export default {
  props: {
    content: String,
    theme: String
  }
};
</script>
<style lang="scss" scoped>
.Paragraph {
  @include text-m;
  @include color-combinations;

  margin-bottom: 1.5rem;
  
}
</style>
