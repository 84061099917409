<template>
  <div class="info-popup">
    <IconButton
      class="info-popup__close-btn"
      icon="close"
      iconfill="stroke"
      @onClick="$emit('close')"
    />
    <img
      class="info-popup__legend"
      :src="
        `${$baseUrl}images/projects/${theme}/${legendImage}-${language}-m.png`
      "
      alt=""
    />
  </div>
</template>

<script>
import IconButton from "../IconButton.vue";
export default {
  props: {
    theme: {
      type: String,
      required: true
    },
    legendImage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      language: null
    };
  },
  created() {
    this.language = this.$i18n.locale;
  },
  components: {
    IconButton
  }
};
</script>

<style lang="scss" scoped>
.info-popup {
  $popup-padding: 1rem;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  backdrop-filter: blur(7px);

  &__close-btn {
    position: absolute;
    right: $popup-padding;
    top: 50%;
    // 115.5px if half of legend img
    transform: translateY(calc(-115.5px - 100% - #{$popup-padding}));
  }

  &__legend {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    // width: calc(100% - (#{$popup-padding} * 2));
    max-width: 100%;
  }
}
</style>
