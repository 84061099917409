<template>
  <div
    class="project-navigation"
    :style="{ '--nav-color': `var(--primary-${this.theme}_40)` }"
  >
    <IconButton
      class="project-navigation__nav-btn previous"
      icon="arrow-left"
      :aria-label="navContent.previous"
      :height="arrowSize"
      @onClick="$emit('toPreviousProject')"
    />
    <p>{{ navContent.label }}</p>
    <IconButton
      class="project-navigation__nav-btn next"
      icon="arrow-left"
      :aria-label="navContent.next"
      :height="arrowSize"
      @onClick="$emit('toNextProject')"
    />
  </div>
</template>

<script>
import IconButton from "../IconButton.vue";
export default {
  props: {
    theme: {
      type: String,
      required: true
    },
    navContent: {
      type: Object,
      required: true
    }
  },

  computed: {
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    },
    arrowSize() {
      return this.smallscreen ? "1.25rem" : "1.75rem";
    }
  },
  components: {
    IconButton
  }
};
</script>

<style lang="scss" scoped>
.project-navigation {
  @include button-m-bold;
  // @include button-l;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  text-transform: uppercase;
  color: $white;

  &__nav-btn {
    &.next {
      transform: rotate(180deg);
    }
  }

  .tablet &,
  .mobile & {
    @include details;

    text-transform: initial;
    background-color: var(--nav-color);
    border-radius: 20px;
    padding: 0.5rem;
  }
}
</style>
