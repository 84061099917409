import {
  WebGLRenderer,
  PerspectiveCamera,
  Scene,
  PMREMGenerator,
  sRGBEncoding,
  LoadingManager,
  AmbientLight,
  DirectionalLight
} from "three";

import { debounce as _debounce, throttle as _throttle } from "lodash";

import { RoughnessMipmapper } from "three/examples/jsm/utils/RoughnessMipmapper.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

import { Tween, Easing, update as tweenUpdate } from "tween";

import { SceneContent } from "@/utilities/classes/sceneContent.js";
import {
  radialGradientShader,
  radialGlowShader
} from "@/utilities/three-functions/shaders.js";

import { threeShapes } from "./three-shapes";
import { threeText } from "./three-text";
import { threeInteraction } from "./three-interaction";

import { mapActions } from "vuex";

export const threeSetup = {
  data() {
    return {
      canvas: null,
      camera: null,
      scene: null,
      renderer: null,
      dracoLoader: new DRACOLoader(), // It's better to reuse one DRACOloader
      pmremGenerator: null,
      loadingManager: new LoadingManager(),
      gltfLoader: null,
      loaded: false
    };
  },
  computed: {
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    },
    scenes() {
      if (this.content.scenes) {
        return Object.keys(this.content.scenes).map(
          sceneId => new SceneContent(sceneId)
        );
      }
    }
  },
  methods: {
    addCamera(container) {
      const camera = new PerspectiveCamera(
        25,
        // 45,
        container.clientWidth / container.clientHeight,
        0.01,
        50
      );

      camera.position.z = 9;
      camera.updateProjectionMatrix();
      camera.lookAt(0, 0, 0);

      return camera;
    },
    addLights(lights, scene) {
      lights.map(light => {
        if (light.position) light.light.position.set(...light.position);
        scene.add(light.light);
      });
    },
    addRenderer(canvas) {
      this.renderer = new WebGLRenderer({
        canvas,
        alpha: true,
        antialias: true
      });

      // This is to make sure that the 3D scenes look the same across devices
      this.renderer.setPixelRatio(2);
      // this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(canvas.clientWidth, canvas.clientHeight);

      if (this.toneMapping) this.renderer.toneMapping = this.toneMapping;
      this.renderer.toneMappingExposure = 0.8;
      this.renderer.outputEncoding = sRGBEncoding;
      this.renderer.autoClear = false;
    },
    addScene() {
      return new Scene();
    },
    addControls(camera, element) {
      const controls = new OrbitControls(camera, element);
      controls.autoRotate = true;
      controls.autoRotateSpeed = 0.25;
      controls.enablePan = false;

      controls.update();
      return controls;
    },

    setControls(sceneContent, fixedAngle) {
      sceneContent.controls = this.addControls(
        sceneContent.camera,
        sceneContent.container
      );

      // Limit rotation of axes
      sceneContent.controls.enableZoom = false;
      sceneContent.controls.enableDamping = true;
      sceneContent.controls.touches.TWO = null;
      // This locks rotation on x-axis.
      sceneContent.controls.minPolarAngle = Math.PI * fixedAngle;
      sceneContent.controls.maxPolarAngle = Math.PI * fixedAngle;
      sceneContent.controls.update();
    },

    addPMREMGenerator() {
      this.pmremGenerator = new PMREMGenerator(this.renderer);
      this.pmremGenerator.compileEquirectangularShader();
    },

    // use of RoughnessMipmapper is optional
    addRoughnessMipmapper() {
      this.roughnessMipmapper = new RoughnessMipmapper(this.renderer);
    },

    setupLoadingManager() {
      this.loadingManager.onLoad = () => {
        this.loaded = true;
      };
    },

    setupDracoLoader() {
      this.dracoLoader.setDecoderPath(`${this.$baseUrl}decoders/`);
      this.dracoLoader.preload();
    },

    setupGLTFLoader() {
      this.gltfLoader = new GLTFLoader(this.loadingManager);
      this.gltfLoader.setDRACOLoader(this.dracoLoader);
    },

    addSceneSetup(sceneId, sceneContent, sceneIndex, modelCallback) {
      sceneContent.container = document.getElementById(sceneId);
      sceneContent.camera = this.addCamera(sceneContent.container);
      sceneContent.scene = this.addScene();

      this.setControls(sceneContent, 0.5);

      this.addLights(
        [
          {
            light: new DirectionalLight(this.lightColors[0], 0.9),
            position: [0, 1, 1]
          },
          { light: new AmbientLight(this.lightColors[1], 0.5) }
        ],
        sceneContent.scene
      );

      // Add objects
      this.addRing(
        sceneContent.sphereGroup,
        this.scenesConfig[sceneIndex].ringGroupRotation,
        this.scenesConfig[sceneIndex].ringGroupPosition
      );
      modelCallback();

      this.addSpheres(
        this.spheresConfig[sceneId],
        sceneContent,
        radialGradientShader,
        this.gradientColor,
        this.onSphereClick,
        this.scenesConfig[sceneIndex].ringGroupRotation,
        sceneId + "_" + sceneIndex + "_"
      );

      // Add glow
      this.addSpheres(
        this.spheresConfig[sceneId],
        sceneContent,
        radialGlowShader,
        this.glowOptions,
        this.onSphereClick,
        this.scenesConfig[sceneIndex].ringGroupRotation
      );

      sceneContent.scene.add(sceneContent.sphereGroup);

      // Manipulate camera a bit to start out from a different perspective
      sceneContent.camera.position.x = -4;
      if (this.smallscreen) sceneContent.controls.update();

      sceneContent.controls.addEventListener("change", () => {
        // z is either (kinda) 0 or Math.PI or Math.PI * -1
        const halfCircleRotation =
          sceneContent.camera.rotation.x > 1 ||
          sceneContent.camera.rotation.x < -1;

        const cameraYRotation = !halfCircleRotation
          ? sceneContent.camera.rotation.y
          : Math.PI - sceneContent.camera.rotation.y;

        this.makeTextFaceFront(
          sceneContent.textBlocks,
          cameraYRotation,
          sceneContent.sphereGroup.rotation.z
        );
      });
    },

    init3D() {
      this.addRenderer(this.canvas);
      this.addPMREMGenerator();
      this.addRoughnessMipmapper();
      this.setupLoadingManager();
      this.setupDracoLoader();
      this.setupGLTFLoader();

      this.scenes.forEach((sceneContent, i) =>
        this.addSceneSetup(sceneContent.id, sceneContent, i, () =>
          this.addModel(sceneContent, this.scenesConfig[i])
        )
      );
    },

    setTween(objectToAnimate, newValue, duration = 1000) {
      return new Tween(objectToAnimate)
        .to(newValue, duration)
        .easing(Easing.Quadratic.InOut)
        .start();
    },

    onResize() {
      this.scenes.forEach(scene => {
        scene.camera.aspect =
          scene.container.clientWidth / scene.container.clientHeight;
        scene.camera.updateProjectionMatrix();
      });

      // We do window width and height here, this also updates canvas size
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },

    animate() {
      requestAnimationFrame(this.animate);

      this.renderer.setScissorTest(false);
      this.renderer.clear(true, true);
      this.renderer.setScissorTest(true);

      this.scenes.forEach(scene => {
        scene.scene.updateMatrixWorld();
        scene.controls.update();
        tweenUpdate();

        this.renderSceneOnScreen(scene);
      });
    },
    ...mapActions(["toggleAutoScroll", "setIndexFromMenu"])
  },
  created() {
    this.toggleAutoScroll(false);
    // this.setIndexFromMenu(7);

    if (this.smallscreen) {
      this.setSmallScreenConf();
    }
  },
  mounted() {
    this.canvas = document.getElementById(this.pageId);
    this.init3D();
    this.animate();

    document.addEventListener("wheel", _debounce(this.onMouseWheel));

    this.$refs.sceneContainer.$el.addEventListener("click", e =>
      this.onSceneInteraction(e, this.onSceneClick)
    );

    // Throttle for performance
    this.$refs.sceneContainer.$el.addEventListener(
      "mousemove",
      _throttle(e => this.onSceneInteraction(e, this.onSceneHover), 100)
    );

    window.addEventListener("resize", _debounce(this.onResize));
  },
  watch: {
    smallscreen(value) {
      if (value) {
        this.setSmallScreenConf();
      }
    }
  },
  mixins: [threeShapes, threeText, threeInteraction]
};
