<template>
  <div
    ref="panel"
    class="detail-panel"
    :style="{
      '--gradient-1': `var(--primary-${theme}-light)`,
      '--gradient-2': `var(--primary-${theme}-lighter)`
    }"
  >
    <IconButton
      v-if="!smallscreen"
      class="detail-panel__close-btn"
      label="close details"
      icon="close"
      :color="`--primary-${theme}`"
      iconfill="stroke"
      @onClick="$emit('closeDetails')"
    />
    <component
      v-for="(el, i) in componentsWithAlignedImages"
      :is="el.component"
      :content="el.content"
      :theme="theme"
      :key="'project-component' + i"
    />
  </div>
</template>

<script>
import Tag from "@/components/project-detail/Tag";
import KPI from "@/components/project-detail/KPI";
import Link from "@/components/project-detail/Link";
import Title from "@/components/project-detail/Title";
import Menu3D from "@/components/UI/3DScenes/Menu3D";
import Paragraph from "@/components/project-detail/Paragraph";
import ImgComponent from "@/components/project-detail/ImgComponent";
import VideoComponent from "@/components/project-detail/VideoComponent";
import IconButton from "../IconButton.vue";

export default {
  components: {
    Tag,
    KPI,
    Link,
    Title,
    Menu3D,
    Paragraph,
    ImgComponent,
    IconButton,
    VideoComponent,
  },
  props: {
    theme: {
      type: String,
      required: true
    },
    components: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      panelHeight: null
    };
  },
  computed: {
    componentsWithAlignedImages() {
      let alignLeft = false;
      return this.components.map(component => {
        if (component.component === "ImgComponent") {
          component.content = { ...component.content, alignLeft };
          alignLeft = !alignLeft;
        }

        return component;
      });
    },
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    }
  },
  mounted() {
    this.panelHeight = this.smallscreen
      ? window.innerHeight * 0.5
      : window.innerHeight;
  },
  watch: {
    components() {
      this.$nextTick(() => {
        this.$refs.panel.scrollTo(0, 0);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-panel {
  $padding-v: 4rem;
  $padding-h: 3.5rem;

  position: relative;
  width: 50vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  right: 0;
  padding: $padding-v $padding-h;
  overflow: auto;
  background-image: linear-gradient(var(--gradient-1), var(--gradient-2));
  text-align: left;

  &__close-btn {
    position: absolute;
    top: calc(#{$padding-v} * 0.67);
    right: $padding-h;
  }

  .tablet &,
  .mobile & {
    width: 100vw;
    height: 50vh;
    height: calc(var(--vh, 1vh) * 50);
    top: 50%;
    padding: 1.5rem;
  }
}
</style>
