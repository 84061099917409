<template>
  <div class="audio-player">
    <audio
      ref="player"
      :src="`${$baseUrl}audio/${audioTrack}.mp3`"
      :autoplay="autoPlay"
      loop
    />
    <IconButton
      :icon="play ? 'headphones' : 'pause'"
      iconfill="both"
      :disabled="fading"
      label="Play o pause audio"
      v-bind="theme ? { color: `--primary-${theme}` } : {}"
      @onClick="togglePlay"
    />
  </div>
</template>

<script>
import IconButton from "./IconButton.vue";

export default {
  components: { IconButton },
  data() {
    return {
      play: true,
      fading: false
    };
  },
  props: {
    audioTrack: {
      type: String,
      required: true
    },
    theme: String,
    autoPlay: {
      type: Boolean,
      default: true // I'd prefer this to be default false, but then passing true doesn't seem to start autoPlay
    }
  },
  created() {
    this.play = this.autoPlay;
  },
  methods: {
    togglePlay() {
      this.play = !this.play;

      if (this.play) {
        if (this.$browserDetect.isIOS) {
          this.$refs.player.play();
        } else {
          this.fadeAudio();
        }
      } else {
        if (this.$browserDetect.isIOS) {
          this.$refs.player.pause();
        } else {
          this.fadeAudio(true);
        }
      }
    },
    fadeAudio(decrease) {
      if (!decrease) {
        this.$refs.player.play();
        this.$refs.player.volume = 0.0;
      }

      this.fading = true;

      const fadeAudio = setInterval(() => {
        if (!this.$refs.player) {
          clearInterval(fadeAudio);
        }

        const newVolume = decrease
          ? this.$refs.player.volume - 0.1
          : this.$refs.player.volume + 0.1;

        if (newVolume > 0 && newVolume < 1) {
          this.$refs.player.volume = newVolume;
        } else {
          this.fading = false;

          if (decrease) {
            this.$refs.player.pause();
          }
          clearInterval(fadeAudio);
        }
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped></style>
