<template>
  <div
    class="ImgComponent"
    :class="[theme, { 'left-align': content.alignLeft }]"
    :style="{ '--drop-shadow-color': `var(--primary-${theme}_60)` }"
  >
    <img
      :src="
        `${$baseUrl}images/projects/${theme}/${content.imgName}${
          mobile ? '-m' : ''
        }.jpg`
      "
      class="ImgComponent__img"
      :alt="content.altText"
    />
    <div class="ImgComponent__dida" v-html="content.dida"></div>
  </div>
</template>
<script>
export default {
  props: {
    content: Object,
    theme: String
  },
  computed: {
    mobile() {
      return this.$tvaMq === "mobile";
    }
  }
};
</script>
<style lang="scss" scoped>
.ImgComponent {
  @include color-combinations;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px 0;

  &.left-align {
    align-items: flex-end;
  }

  &__img {
    // width: 525px;
    // max-width: 100%;
    width: 100%;
    filter: drop-shadow(0px 0px 7px var(--drop-shadow-color));
  }
  &__dida {
    @include details;
    margin-top: 10px;
  }

  .mobile &,
  .tablet & {
    max-width: 500px;
    margin: 0 auto;
  }
}
</style>
