<template>
  <div class="Kpi__container">
    <div
      class="Kpi"
      :class="[theme, { numerical: kpi.numerical }]"
      v-for="kpi in content"
      :key="kpi.value"
    >
      <div class="Kpi__value" v-html="kpi.value" />
      <div class="Kpi__label" v-html="kpi.label" />
      <div :class="['Kpi__bg', theme]"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    content: Array,
    theme: String
  }
};
</script>
<style lang="scss" scoped>
.Kpi {
  @include color-combinations;
  position: relative;
  width: 50%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  height: 100%;

  &__bg {
    position: absolute;
    border-radius: 12px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include color-combinations-background;
    opacity: 0.05;
  }
  &:first-of-type {
    margin: 0 1rem 0 0;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    align-content: stretch;
    margin: 1.5rem 0;
  }

  &__label {
    @include details;
  }

  &__value,
  &__label {
    font-family: "Fraunces";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
  }

  &__value::first-letter {
    text-transform: lowercase;
  }

  .mobile & {
    &.numerical {
      flex-direction: column;
      .Kpi__label {
        margin-left: 0;
      }
    }
  }

  &.numerical {
    flex-direction: row;

    .Kpi__label {
      font-weight: 400;
      margin-left: 1rem;
    }
  }
}
</style>
