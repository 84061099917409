<template>
  <div class="video-component">
    <youtube :video-id="content.videoId" ref="youtube" :fitParent="true" />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.video-component {
  margin-bottom: 2rem;
}
</style>
