<template>
  <div class="Tag__container">
    <div class="Tag" :class="[theme]" v-for="tag in content" :key="tag">
      {{ tag }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    content: Array,
    theme: String
  }
};
</script>
<style lang="scss" scoped>
.Tag {
  @include color-combinations-with-border;
  @include details;
  text-transform: uppercase;
  padding: 2px 10px;
  border-radius: 50px;
  margin: 0 7px;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
</style>
