import { Group, MeshBasicMaterial, Mesh, PlaneGeometry } from "three";

import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";

export const threeText = {
  data() {
    return {
      fontLoader: new FontLoader(),
      textMaterial: new MeshBasicMaterial({
        toneMapped: false,
        transparent: true
      }),
      maxStringLength: 36,
      bgColorScheme: {
        sky: "hsl(209, 100%, 37%)",
        earth: "hsl(123, 34%, 49%)",
        sea: "hsl(237, 100%, 60%)"
      },
      paragraphLineHeight: 0.1,
      paragraphMarginTop: 0.18,
      paragraphFontSize: 0.06,
      titleFontSize: 0.07
    };
  },
  mounted() {
    if (this.smallscreen) {
      this.setSmallscreenFontSize();
    }
  },
  methods: {
    setSmallscreenFontSize() {
      this.paragraphFontSize = 0.03;
      this.titleFontSize = 0.04;
    },

    // make sure text are always faced in the camera direction;
    makeTextFaceFront(textBlocks, cameraYRotation, sphereGroupRotationZ) {
      textBlocks.forEach(block => {
        block.rotation.y = cameraYRotation + sphereGroupRotationZ;
      });
    },

    addTitle(title, mainBlock, isMain) {
      return this.fontLoader.load(
        `${this.$baseUrl}font/DM_Sans_Bold.json`,
        font => {
          const titleGeometry = new TextGeometry(title, {
            font,
            size: 0.07,
            height: 0.001 // Depth of font
          });

          titleGeometry.computeBoundingBox();

          if (!isMain) {
            titleGeometry.center();
          }

          const titleWidth =
            titleGeometry.boundingBox.max.x - titleGeometry.boundingBox.min.x;
          const titleMesh = new Mesh(titleGeometry, this.textMaterial);
          const underline = this.addUnderline(titleWidth, isMain);

          mainBlock.add(titleMesh);
          mainBlock.add(underline);
        }
      );
    },

    addParagraph(textLines, mainBlock) {
      return this.fontLoader.load(
        `${this.$baseUrl}font/DM_Sans_Medium.json`,
        font => {
          let top = this.paragraphMarginTop * -1;

          textLines.forEach(line => {
            const textGeometry = new TextGeometry(line, {
              font,
              size: 0.06,
              height: 0.001 // Depth of font
            });

            textGeometry.computeBoundingBox();

            const textMesh = new Mesh(textGeometry, this.textMaterial);
            textMesh.position.y += top;
            textMesh.name = "pLine";

            top -= this.paragraphLineHeight;

            mainBlock.add(textMesh);
          });
        }
      );
    },

    splitText(text) {
      if (text.length <= this.maxStringLength) {
        return text;
      }

      // https://stackoverflow.com/questions/14484787/wrap-text-in-javascript
      // Returns an array of sentences of n characters, but doesn't break words
      var reg = new RegExp(
        `(?![^\\n]{1,${this.maxStringLength}}$)([^\\n]{1,${this.maxStringLength}})\\s`,
        "g"
      );

      const lines = text.match(reg);
      let lastLine = text;

      // Since the regex doesn't return the last line if it's less than 34 characters, we do it manually
      lines.forEach(line => (lastLine = lastLine.replace(line, "")));
      lines.push(lastLine);

      return lines;
    },

    addTextToSphere(
      sphere,
      sphereMesh,
      isMain,
      groupRotation,
      sceneContent,
      namePostfix
    ) {
      const mainBlock = new Group();

      let xText = isMain ? 0.3 : 0;
      let yText = isMain ? -0.1 : 0;
      let zText = isMain ? -1.15 : 0.3;
      let meshAmount = 2; // Default amount: 1 for title, 1 for underline

      mainBlock.position.set(
        sphereMesh.position.x + xText,
        sphereMesh.position.y + yText,
        // Because of rotation of spheregroup, z axis has the effect that
        // it looks for the viewer like changes on the y axis
        sphereMesh.position.z + zText
      );

      mainBlock.rotation.x = groupRotation.x * -1;

      // Correct text rotation with start of camera rotation y (-0.42) & ringgroup rotation.
      // This will only affect first scene, since the others will be corrected in controls "change" handler.
      const ringRotation = this.scenesConfig[0].ringGroupRotation;

      mainBlock.rotation.y = -0.42;
      if (ringRotation.z) mainBlock.rotation.y += ringRotation.z;

      const title = sphere.title.toUpperCase();
      this.addTitle(title, mainBlock, isMain);

      if (sphere.text) {
        const textLines = this.splitText(sphere.text);
        meshAmount += textLines.length;
        this.addParagraph(textLines, mainBlock);
      }

      // Use requestAnimationFrame to get block width and height when children are added,
      // and then add block to sphereGroup
      requestAnimationFrame(() =>
        this.finalizeMainBlock(mainBlock, sceneContent, meshAmount, namePostfix)
      );
    },

    getBlockSize(block) {
      let blockHeight = 0;
      let blockWidth = 0;

      block.children.forEach(child => {
        if (child.isMesh) {
          child.geometry.computeBoundingBox();
          const width =
            child.geometry.boundingBox.max.x - child.geometry.boundingBox.min.x;

          // If the child is a line from the paragraph, it's height === line height
          // (not actually, but taking it's y-position in account it does)
          const height =
            child.name === "pLine"
              ? this.paragraphLineHeight
              : child.geometry.boundingBox.max.y -
                child.geometry.boundingBox.min.y;

          blockHeight += height;

          // Adjust the block width to the longest line
          blockWidth = blockWidth > width ? blockWidth : width;
        }
      });

      return [blockWidth, blockHeight];
    },

    finalizeMainBlock(
      mainBlock,
      sceneContent,
      amountBlockChildren,
      namePostfix
    ) {
      if (mainBlock.children.length !== amountBlockChildren) {
        requestAnimationFrame(() =>
          this.finalizeMainBlock(
            mainBlock,
            sceneContent,
            amountBlockChildren,
            namePostfix
          )
        );
      } else {
        const [blockWidth, blockHeight] = this.getBlockSize(mainBlock);
        const isMain = amountBlockChildren !== 2;

        const background = this.addBackground(
          blockWidth,
          blockHeight + (isMain ? this.paragraphMarginTop * 0.5 : 0),
          isMain
        );

        background.name = "plane_" + namePostfix;
        background.callback = bgMesh => this.onSphereClick(bgMesh);

        mainBlock.add(background);

        sceneContent.sphereGroup.add(mainBlock);
        sceneContent.textBlocks.push(mainBlock);
        sceneContent.intersectObjects.push(background);
      }
    },

    addUnderline(width, align) {
      const geometry = new PlaneGeometry(width, 0.01);

      if (align) {
        geometry.computeBoundingBox();
        geometry.translate(width * 0.5, -0.04, 0);
      } else {
        geometry.translate(0, -0.05, 0);
      }

      const plane = new Mesh(geometry, this.textMaterial);

      return plane;
    },

    addBackground(width, height, align) {
      const padding = 0.05;
      const geometry = new PlaneGeometry(
        width + padding * 2,
        height + padding * 2
      );

      if (align) {
        geometry.translate(width * 0.5, height * -0.5 + padding * 1.5, -0.01);
      } else {
        geometry.translate(0, 0, -0.01);
      }

      const plane = new Mesh(
        geometry,
        new MeshBasicMaterial({
          color: this.bgColorScheme[this.pageId],
          transparent: true,
          opacity: 0.5,
          alphaTest: 0.001
        })
      );

      return plane;
    }
  },
  watch: {
    smallscreen(value) {
      if (value) {
        this.setSmallscreenFontSize();
      } else {
        this.paragraphFontSize = 0.06;
        this.titleFontSize = 0.07;
      }
    }
  }
};
